import React from "react";
import { Col, Container, Row } from "reactstrap";
import TeamCard from "../components/TeamCard";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const HomePage = (props) => {
  return (
    <Container fluid className="p-homepage p-0">
      {/* HEADER SECTION */}
      <Row
        style={{
          backgroundImage: "url(/img/home.jpg)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          width: "100%",
        }}
        className="m-0"
      >
        <Row className="p-homepage-row_header m-0">
          {/* Header-left */}
          <Col
            lg="6"
            md="12"
            className="p-homepage-row_header_header-left p-lg-5"
          >
            <Fade left>
              <h1 className="p-homepage-row_header_header-left_title">
                Une idée de projet ?
              </h1>
              <p className="p-homepage-row_header_header-left_text">
                Vous avez une idée ? Faites la décoller 🚀
                <br />
                <br />
                Besoin d’un <strong className="text-strong">site web</strong>,
                de mettre en place un{" "}
                <strong className="text-strong">e-commerce</strong> ou une{" "}
                <strong className="text-strong">application mobile</strong> ?
                <br />
                <br />
                Experts en{" "}
                <strong className="text-strong">
                  programmation informatique
                </strong>
                , nous vous accompagnons pas à pas;
                <br />
                vous pourrez à tout moment suivre le{" "}
                <strong className="text-strong">développement</strong> de votre
                projet et maîtriser son aboutissement.
                <br />
                <br />
                Que vous soyez seulement sur la base de lancement ou déjà en
                orbite,
                <br />
                <strong className="text-strong">contactez-nous&nbsp;</strong>
                et nous vous aiderons à décoller ou atterrir en toute sécurité.
              </p>
              <Link
                className="a-buttons-btn a-buttons-btn_link"
                to="/contact"
                onClick={() => window.scrollTo(0, 0)}
              >
                Nous Contacter&nbsp;
                <i className="far fa-envelope" />
              </Link>
            </Fade>
          </Col>
          {/* Header right */}
          <Col
            lg="6"
            md="12"
            className="p-homepage-row_header_header-right d-none d-lg-flex"
          >
            <Fade right>
              <img
                className="p-homepage-row_header_header-right_img"
                src="/img/idea.svg"
                alt="development idea website mobile application"
              />
            </Fade>
          </Col>
        </Row>
      </Row>

      {/* ABOUT SECTION */}
      <Row className="p-homepage-row_about pb-5 m-0">
        <Container className="pt-5 pb-5">
          <Row className="d-flex justify-content-center align-items-center">
            <Col
              lg="6"
              md="12"
              className="p-homepage-row_about_leftSection"
            >
              <Fade left>
                <img
                  className="p-homepage-row_about_leftSection_img pb-4"
                  src="/img/team.svg"
                  alt="development team website mobile application"
                />
              </Fade>
            </Col>
            <Col
              className="p-homepage-row_about_rightSection"
              lg="6"
              md="12"
            >
              <Fade bottom>
                <h2 className="p-homepage-row_about_rightSection_title">
                  Qui sommes nous ?
                </h2>
                <p className="p-homepage-row_about_rightSection_text">
                  <strong className="text-strong">APPePLAY Studio</strong> c’est
                  l’histoire de deux Normands passionnés et téméraires, qui sont
                  partis sur l’idée folle de créer un{" "}
                  <strong className="text-strong">jeu mobile</strong>. De cette
                  idée est née la volonté de s’associer et de fonder{" "}
                  <strong className="text-strong">APPePLAY Studio</strong> !
                  <br /><br />
                  Dans notre nom, comprenez APP pour{" "}
                  Application E pour Internet et PLAY en référence au
                  jeu qui nous a réuni.
                  <br /><br />
                  Notre première envie a tout de suite été de travailler sur la
                  conception{" "}
                  <strong className="text-strong">d’application mobile</strong>,
                  de donner vie à nos idées, mais aussi aux vôtres ! Mais pas
                  seulement ! Nous vous proposons aussi un accompagnement dans la
                  création de votre{" "}
                  <strong className="text-strong">site internet</strong>, cela va
                  du simple <strong className="text-strong">site statique{" "}</strong>
                  au <strong className="text-strong">site spécifique</strong>, sur
                  mesure, en passant par le{" "}
                  <strong className="text-strong">site vitrine</strong>, le{" "}
                  <strong className="text-strong">e-commerce</strong> ou{" "}
                  <strong className="text-strong">l'application mobile</strong>
                  .<br />
                  <br />
                  Alors que vous soyez entreprise publique ou privée,{" "}
                  <strong className="text-strong">start-up</strong>, particulier,
                  nous mettons tout en œuvre pour{" "}
                  <strong className="text-strong">développer</strong> avec vous
                  vos besoins, vos envies, vos visions.
                </p>
                <Link
                  className="a-buttons-btn a-buttons-btn_link"
                  to="/service"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  En savoir plus&nbsp;
                  <i className="fas fa-angle-right ml-2" />
                </Link>
              </Fade>
            </Col>
          </Row>
        </Container>
        <div className="custom-shape-divider-bottom-1633513196">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
        </div>
      </Row>

      {/* TEAM SECTION */}
      <Row className="p-homepage-row_team m-0">
        <Col lg="12" className="p-homepage-row_team_titleContainer">
          <h2 className="p-homepage-row_team_title">Les Cofondateurs</h2>
          <h4 className="p-homepage-row_reinforcement_subtitle">
            La fusion de leurs compétences donne vie à APPePLAY Studio !
          </h4>
        </Col>
        <div className="custom-shape-divider-top-1633591324">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
        </div>
        <Col lg="4" md="12">
          <Fade bottom>
            <TeamCard
              src="/img/timothée.png"
              title="Timothée Poulain"
              subtitle="Développeur Back-End"
              text="Toujours prêt à mouiller sa chemise, Timothée est la voix qui nous guide jusqu'à la réalisation finale du projet. Avec une certaine appétence pour les jeux de tous types et sur tous supports, il aime tout ce qui touche à la créativité et à la nouveauté. Doté lui-même d’une inventivité débordante, Il est la personne idéale si vous souhaitez réaliser des outils ou des projets innovants !"
              textLink="Me contacter"
              to="/contact"
              iconLinkedIn={<i className="m-teamCard_fab fab fa-linkedin" />}
              linkedin="https://fr.linkedin.com/in/timothee-poulain-983a5a188?trk=people-guest_people_search-card"
              linkedInTitle="Voir Timothée sur LinkedIn"
              iconFacebook={<i className="m-teamCard_fab fab fa-facebook" />}
              facebook="https://www.facebook.com/Majinthanat"
              facebookTitle="Voir Timothée sur Facebook"
            />
          </Fade>
        </Col>
        <Col lg={{ size: 4, offset: 1 }} md="12">
          <Fade bottom>
            <TeamCard
              src="/img/nicolas.png"
              title="Nicolas Benoist"
              subtitle="Développeur Front-End"
              text="Nicolas possède de nombreuses casquettes (au sens propre comme au figuré) et peu importe votre projet, il aura celle qu'il vous faut ! Développeur front, mais aussi beatmaker et gamer, il possède une connaissance variée et impressionnante des jeux vidéo, de la musique et des comics books.
              Vous pourrez compter sur lui pour concrétiser votre projet sous toutes ses facettes."
              textLink="Me contacter"
              to="/contact"
              iconLinkedIn={<i className="m-teamCard_fab fab fa-linkedin" />}
              linkedin="https://www.linkedin.com/in/nicolas-benoist-75266a165?trk=people-guest_people_search-card"
              linkedInTitle="Voir Nicolas sur LinkedIn"
              iconFacebook={<i className="m-teamCard_fab fab fa-facebook" />}
              facebook="https://www.facebook.com/nicolas.benoist.184"
              facebookTitle="Voir Nicolas sur Facebook"
              iconTwitter={
                <i className="m-teamCard_fab fab fa-twitter-square" />
              }
              twitter="https://twitter.com/NicolasBenoist2"
              twitterTitle="Voir Nicolas sur Twitter"
            />
          </Fade>
        </Col>
        <div className="custom-shape-divider-bottom-1633512800">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
        </div>
      </Row>
      <Row className="p-homepage-row_reinforcement m-0">
        <Col lg="12" className="p-homepage-row_reinforcement_titleContainer">
          <h2 className="p-homepage-row_reinforcement_title">Les Renforts</h2>
          <h4 className="p-homepage-row_reinforcement_subtitle">
            Parce que plus on est de fous mieux on bosse !
          </h4>
        </Col>
        <div className="custom-shape-divider-top-1633591324">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
        </div>
        <Col xl="3" lg="5" md="12">
          <Fade bottom>
            <TeamCard
              src="/img/houda.png"
              title="Houda Begbeg"
              subtitle="Développeuse"
              text="Discrète et d’un tempérament calme, Houda aime le travail d’équipe. Elle a intégré le monde du web en 2019 et se hisse au rang de chef de projet digital l’année suivante.
              Challengeuse, sa pratique du quad, dénote chez elle une réactivité et une capacité d’adaptation. C’est un véritable atout pour affronter toutes les situations !"
              iconLinkedIn={<i className="m-teamCard_fab fab fa-linkedin" />}
              linkedin="https://www.linkedin.com/in/houda-b-0bab7b194/"
              linkedInTitle="Voir Houda sur LinkedIn"
              iconInstagram={<i className="m-teamCard_fab fab fa-instagram" />}
              instagram="https://www.instagram.com/houuda_hou/"
              instagramTitle="Voir Houda sur Instagram"
            />
          </Fade>
        </Col>
        <Col xl="3" lg="5" md="12">
          <Fade bottom>
            <TeamCard
              src="/img/sonia.png"
              title="Sonia Hizi"
              subtitle="Développeuse junior"
              text="Sociable, positive et curieuse, Sonia s’est lancée dans l’aventure web il y a trois ans après des études de mathématiques ! Autodidacte, elle a décidé de mettre ses nombreuses compétences à notre service. Ancienne championne de Kick-boxing, grâce à son esprit combatif et son sens du challenge elle relèvera tous vos défis !"
              iconLinkedIn={<p aria-hidden="true"></p>}
            />
          </Fade>
        </Col>
        <Col xl="3" lg="5" md="12">
          <Fade bottom>
            <TeamCard
              src="/img/kevin.png"
              title="Kevin Fontaine"
              subtitle="Graphiste"
              text="Réservé, mais enthousiaste, Kevin est aussi un passionné de jeux vidéo et de jeux de plateaux. Ses passions et ses activités quotidiennes telles que le modélisme font de lui une personne stratégique, minutieuse et inventive. Son souci du détail et sa droiture seront des atouts pour mener à bien vos projets."
              iconLinkedIn={<p aria-hidden="true"></p>}
            />
          </Fade>
        </Col>
        <Col xl="3" lg="5" md="12">
          <Fade bottom>
            <TeamCard
              src="/img/margaux.png"
              title="Margaux Ohlmann"
              subtitle="Assistante administrative"
              text="Sensible et intuitive, Margaux est aussi enjouée et communicative ! Extérieure au monde du web, elle nous aide à gérer le superflu que représente la charge administrative.
              Une interrogation, un doute ? C’est à elle qu’on s’adresse. Elle trouvera toujours les mots pour vous faire avancer ou vous mettre en valeur."
              iconLinkedIn={<i className="m-teamCard_fab fab fa-linkedin" />}
              linkedin="https://fr.linkedin.com/in/margaux-ohlmann-761771107"
              linkedInTitle="Voir Margaux sur LinkedIn"
              iconFacebook={<i className="m-teamCard_fab fab fa-facebook" />}
              facebook="https://www.facebook.com/margaux.ohlmann"
              facebookTitle="Voir Margaux sur Facebook"
            />
          </Fade>
        </Col>
        <div className="custom-shape-divider-bottom-1633515307">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
        </div>
      </Row>

      {/* TECHNOS SECTION */}
      <Row className="p-homepage-row_logoTechno m-0">
        <Col className="p-homepage-row_logoTechno_container" lg="12">
          <i
            className="p-homepage-row_logoTechno_logo fab fa-react"
            title="React / React-native"
          />
          <i
            className="p-homepage-row_logoTechno_logo fab fa-php"
            title="PHP"
          />
          <i
            className="p-homepage-row_logoTechno_logo fab fa-html5"
            title="html5"
          />
          <i
            className="p-homepage-row_logoTechno_logo fab fa-css3-alt"
            title="css3"
          />
          <i
            className="p-homepage-row_logoTechno_logo fab fa-sass"
            title="Sass"
          />
          <i
            className="p-homepage-row_logoTechno_logo fab fa-gitlab"
            title="Gitlab"
          />
          <i
            className="p-homepage-row_logoTechno_logo fab fa-google-drive"
            title="Google-drive"
          />
          <i
            className="p-homepage-row_logoTechno_logo fab fa-discord"
            title="Discord"
          />
        </Col>
      </Row>

      {/* PROJECTS SECTION */}
      {/* <Row className="p-homepage-row_projects m-0">
        <Col md="12">
          <h1 className="p-homepage-row_projects_title">Nos Projets</h1>
        </Col>
        <Col lg="3">
          <ProjectCard
            src="https://via.placeholder.com/318x180"
            text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni reiciendis dolor minus velit quasi voluptate! Corrupti nesciunt, deleniti quos, minus iure vero nisi id aut dicta vel doloremque accusamus laborum!"
          />
        </Col>
        <Col lg="3">
          <ProjectCard
            src="https://via.placeholder.com/319x180"
            text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni reiciendis dolor minus velit quasi voluptate! Corrupti nesciunt, deleniti quos, minus iure vero nisi id aut dicta vel doloremque accusamus laborum!"
          />
        </Col>
        <Col lg="3">
          <ProjectCard
            src="https://via.placeholder.com/320x180"
            text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni reiciendis dolor minus velit quasi voluptate! Corrupti nesciunt, deleniti quos, minus iure vero nisi id aut dicta vel doloremque accusamus laborum!"
          />
        </Col>
      </Row> */}
    </Container>
  );
};

export default HomePage;
