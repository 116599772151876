import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import CustomNavbar from './components/Navbar';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import Services from './pages/Services';
import PopupContact from "./components/PopupContact";
// import Loading from './pages/LoadingPage';
import Products from './pages/Products';
import News from './pages/News';
import Blog from './pages/Blog';
import PageNotFound from './pages/PageNotFound';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


require('./scss/index.scss');

function App() {

  const NavbarWithRouter = withRouter(CustomNavbar);

  return (
    <Router>
      <NavbarWithRouter />
      <div className="App">
        <Switch>
          <Route path="/service" component={Services} />
          <Route path="/products" component={Products} />
          <Route path="/news" component={News} />
          <Route path="/blog" component={Blog} />
          <Route path="/contact" component={Contact} />
          <Route exact path="/" component={HomePage} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
      <Footer />
      <PopupContact/>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
    </Router>
  );
}

export default App;
