import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

const items = [
  {
    src: "img/freestyle.png",
    alt: "mobile application android logo Freestyle Drum Machine",
  },
  // {
  //   src: 'https://via.placeholder.com/319x180',
  // },
  // {
  //   src: 'https://via.placeholder.com/320x180',
  // }
];

const Footer = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img
          src={item.src}
          alt={item.altText}
          className="m-footer_contact_column_carousel_img"
          loading="eager"
          width="100%"
          height="100%"
        />
      </CarouselItem>
    );
  });

  return (
    <Container fluid className="m-footer">
      <Row className="m-footer_contact">
        <Col
          lg="4"
          md="6"
          className="m-footer_contact_column pl-0 pl-md-5 text-center text-md-left"
        >
          <h4 className="h4">SIEGE SOCIAL</h4>
          <p>
            <i className="m-footer_fas fas fa-home" />
            &nbsp;76200 Dieppe
          </p>
          <p>
            <i className="m-footer_fas fas fa-mobile-alt" />
            &nbsp;07.77.49.37.57
          </p>
          <p>
            <i className="m-footer_fas fas fa-envelope" />
            &nbsp;contact@appeplay-studio.fr
          </p>
          <p>Du lundi au vendredi de 9h00 à 17h30</p>
        </Col>
        <Col
          lg="4"
          md="6"
          className="m-footer_contact_column align-items-center"
        >
          <h4 className="h4">SUIVEZ-NOUS !</h4>
          <p>Suivez toute notre actualité sur les réseaux !</p>
          <p>
            <a href="https://fr.linkedin.com/company/appeplay-studio?trk=public_profile_topcard-current-company">
              <i className="m-footer_fab fab fa-linkedin ml-4" />
            </a>
            <a href="https://www.facebook.com/Appeplay-Studio-105343098238269">
              <i className="m-footer_fab fab fa-facebook" />
            </a>
            <a href="https://twitter.com/appeplay">
              <i className="m-footer_fab fab fa-twitter-square" />
            </a>
            <a href="https://www.instagram.com/appeplaystudio/">
              <i className="m-footer_fab fab fa-instagram" />
            </a>
            <a href="https://youtube.com/channel/UCOIE-3wDAC0OFyPU5Z2_AYQ">
              <i className="m-footer_fab fab fa-youtube" />
            </a>
            <a href="https://discord.gg/vgmDw4BvEh">
              <i className="m-footer_fab fab fa-discord" />
            </a>
          </p>
        </Col>
        <Col lg="4" className="m-footer_contact_column is--borderNone">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            className="m-footer_contact_column_carousel"
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides}
          </Carousel>
        </Col>
      </Row>
      <Row className="m-footer_copyright">
        <Col lg="12" className="m-footer_copyright_container">
          <img
            className="m-footer_copyright_container_logo"
            src="img/logoWhite.png"
            alt="logo enterprise APPePLAY Studio website mobile application"
            loading="eager"
          />
          <p className="m-footer_copyright_container_text pr-3">
            &copy; Tous droits réservés
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
