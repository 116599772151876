import React from "react";
import { Container } from "reactstrap";
import ComingSoon from "../components/ComingSoon";

const Blog = (props) => {
  return (
    <Container fluid className="p-blog p-0">
      <ComingSoon />
    </Container>
  );
};

export default Blog;
