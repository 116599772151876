import React from "react";
import { Col, Container, Row } from "reactstrap";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const Services = (props) => {
  return (
    <>
      <Container fluid className="p-services p-0">
        {/* Header Section */}
        <Row
          style={{
            backgroundImage: "url(/img/headerService.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            width: "100%",
          }}
          className="m-0"
        >
          <Row className="p-services_header m-0">
            <Col lg="12" className="p-services_header_container">
              <h1 className="p-services_header_container_title">
                APPePLAY Studio vous accompagne
              </h1>
              <p className="p-services_header_container_text">
                Entreprise des Services du Numérique (ESN) fraîchement installée
                en Normandie,
                <br />
                nous souhaitons vous accompagner dans votre projet, de la
                conception à la maintenance.
              </p>
            </Col>
          </Row>
        </Row>

        {/* Design Section */}
        <Container>
          <Row className="p-services_design">
            <Col lg="12" className="p-services_design_title p-md-5 p-4">
              <h1>Design de votre projet</h1>
            </Col>
            <Col lg="6" className="p-services_design_leftSection p-4 mb-5">
              <Fade left>
                <h4 className="h4">Maquettage du site ou de l'application</h4>
                <p className="textGray mb-5">
                  Imaginez que vous deviez faire appel à un constructeur pour
                  bâtir votre maison. Celui-ci devra faire appel à un architecte
                  pour en élaborer les plans. C’est la même chose ici. Avant de
                  vous bâtir un site ou une application, nous devons penser à sa
                  structure et la hiérarchisation de son contenu. C’est donc une
                  phase importante dans l’étape de la conception, celle de la
                  réflexion. À la différence du zoning, qui consiste à schématiser
                  à l’aide de boîtes ou de blocs les pages web de votre site, cela
                  nous permet de vous présenter plus précisément ses
                  fonctionnalités et son contenu. Nous pouvons ainsi aller au plus
                  près de vos attentes et répondre exactement à vos besoins.
                </p>
                <Link
                  className="a-buttons-btn a-buttons-btn_link"
                  to="/contact"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Besoin d'en savoir plus&nbsp;
                  <i className="fas fa-angle-right ml-2" />
                </Link>
              </Fade>
            </Col>
            <Col lg="6" className="p-services_design_rightSection">
              <Fade right>
                <img
                  className="p-services_design_rightSection_img"
                  src="/img/design.svg"
                  alt="design illustration website mobile application"
                />
              </Fade>
            </Col>
          </Row>
        </Container>
        <div class="custom-shape-divider-bottom-1633610291">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
          </svg>
        </div>

        {/* development Section */}
        <Container fluid className="p-services_containerFluid p-0" id="website">
          <Container>
            <Row className="p-services_development">
              <Col lg="12" className="p-services_development_title p-md-5 p-4">
                <h1>Développement technique de votre projet</h1>
              </Col>
              <Col lg="6" className="p-services_development_leftSection p-4">
                <Fade left>
                  <h4 className="h4">Création d'un site statique</h4>
                  <p className="textGray">
                    Vous souhaitez avoir de la visibilité sur le web sans creuser
                    votre porte-monnaie ? C’est un site statique qu’il vous faut !
                    Destiné uniquement à la démonstration, sachez cependant, qu’il
                    ne permet pas d’avoir un espace client, d’être adaptable, ni
                    modifié facilement sans passer par un développeur. Si c’est
                    une vitrine qu’il vous faut alors c’est ce type de site qu’il
                    vous faut, sinon on vous dirigera plutôt vers un site
                    dynamique, plus onéreux, mais plus vivant !
                  </p>
                </Fade>
              </Col>
              <Col lg="6" className="p-services_development_rightSection">
                <Fade bottom>
                  <img
                    className="p-services_development_rightSection_img"
                    src="/img/static.svg"
                    alt="static website illustration"
                  />
                </Fade>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="p-services_development">
              <Col
                lg="6"
                className="p-services_development_rightSection d-none d-md-block"
              >
                <Fade bottom>
                  <img
                    className="p-services_development_rightSection_img"
                    src="/img/development.svg"
                    alt="website illustration"
                  />
                </Fade>
              </Col>
              <Col lg="6" className="p-services_design_leftSection p-md-5 p-4">
                <Fade right>
                  <h4 className="h4">Création de votre site internet</h4>
                  <p className="textGray">
                    Si vous recherchez une interaction avec l’utilisateur, avoir
                    un contenu actif, multiple, alors vous êtes à la recherche
                    d’un site internet dit «dynamique» ! Ce type de site permet
                    d’afficher ce dont l’utilisateur a besoin grâce à des
                    informations stockées dans une base de données; il offre aussi
                    au propriétaire la possibilité de modifier son contenu
                    facilement et de proposer aux visiteurs une expérience
                    d’utilisation personnalisée.
                  </p>
                </Fade>
              </Col>
            </Row>
          </Container>
          <Container id="e-commerce">
            <Row className="p-services_development">
              <Col
                lg="6"
                className="p-services_development_leftSection p-md-5 p-4"
              >
                <Fade left>
                  <h4 className="h4">Création de votre e-commerce</h4>
                  <p className="textGray">
                    La création d’un site e-commerce offre la possibilité de
                    mettre en avant et de vendre des produits, en y proposant des
                    fonctionnalités telles que la mise au panier, le paiement en
                    ligne, mais aussi des fiches d’articles personnalisées et
                    d’autres outils divers et variés. Un tel site permet également
                    à son propriétaire de gérer les stocks et d’avoir un suivi des
                    ventes et des finances.
                  </p>
                </Fade>
              </Col>
              <Col lg="6" className="p-services_development_rightSection">
                <Fade bottom>
                  <img
                    className="p-services_development_rightSection_img"
                    src="/img/e-commerce.svg"
                    alt="e-commerce illustration"
                  />
                </Fade>
              </Col>
              <Col lg="12" className="p-services_development_button p-5">
                <Link
                  className="a-buttons-btn a-buttons-btn_link"
                  to="/contact"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Prendre rendez-vous
                </Link>
              </Col>
            </Row>
          </Container>
        </Container>
        <div class="custom-shape-divider-top-1633610462">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>

        {/* App Mobile Section */}
        <Container className="pb-4" id="mobilApp">
          <Row className="p-services_mobil">
            <Col lg="12" className="p-services_mobil_title p-md-5 p-4">
              <h1>Développement d'application mobile</h1>
            </Col>
            <Col lg="6" className="p-services_mobil_leftSection">
              <Fade bottom>
                <img
                  className="p-services_mobil_leftSection_img"
                  src="/img/mobil.svg"
                  alt="mobile application illustration"
                />
              </Fade>
            </Col>
            <Col lg="6" className="p-services_mobil_rightSection p-4">
              <Fade right>
                <h4 className="h4">Besoin d'une application mobile ?</h4>
                <p className="textGray">
                  Vous avez une idée d’application mobile ?
                  <br />
                  Nous sommes là pour lui donner vie et vous aider à la maintenir
                  et constamment l’améliorer. Entreprise ou particulier,
                  laissez-nous vous aider à concrétiser tous vos projets !
                </p>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Container>
      <div class="custom-shape-divider-bottom-1633610641">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
      </div>
    </>
  );
};

export default Services;
