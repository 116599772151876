import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const PageNotFound = (props) => {
  return (
    <Container fluid className="p-pageNotFound p-0">
      <Row
        className="p-pageNotFound_row m-0"
        style={{
          backgroundImage: "url(/img/404.jpg)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <Col lg="12" className="p-pageNotFound_row_col">
          <h1 className="p-pageNotFound_row_col_title">404 OUPS !</h1>
          <h3 className="p-pageNotFound_row_col_subtitle">Il semblerait que vous soyez perdu...</h3>
        </Col>
      </Row>
    </Container>
  );
}

export default PageNotFound;