import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const NewsModalStage = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div className= 'o-modal'>
      <p className= "o-modal_link" onClick={toggle}>Lire la suite</p>
      <Modal className='modal-lg show' isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>

        </ModalHeader>
        <ModalBody className="p-4 d-lg-flex">
          <video className="o-modal_video" loop autoPlay>
            <source src='img/stage.mp4' type="video/mp4" />
          </video>
          <div className="pl-4">
            <h2>Welcome Alexis !</h2>
            <p>
              Merci à Alexis actuellement en BTS SIO de nous rejoindre dans l'aventure APPePLAY Studio le temps d'un stage.
              Une expérience nouvelle autant pour lui que pour nous car Alexis est notre tout premier stagiaire! 1 mois à nous accompagner dans nos projets et en l'occurence sur l'un de nos projets mobile qui nous l'espèrons verra très vite le jour mais chut on ne vous en dit pas plus pour le moment (un indice sur notre chaine <a href="https://www.youtube.com/channel/UCOIE-3wDAC0OFyPU5Z2_AYQ">Youtube</a>).
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <p>Publié le: 26/05/2021</p>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default NewsModalStage;