import React from "react";
import { Col, Row } from "reactstrap";

const ComingSoon = (props) => {
  return (
    <Row className="o-comingSoon_row m-0 pt-5">
      <Col className="o-comingSoon_row_col" lg={{ size: 6, offset: 3 }}>
        <img
          className="o-comingSoon_row_col_img"
          src="/img/build.svg"
          alt="development products website mobile application"
        />
        <h1 className="o-comingSoon_row_col_title">Prochainement!</h1>
      </Col>
    </Row>
  );
};

export default ComingSoon;
