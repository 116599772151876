import React ,{ useState}from "react";

const Message = (props) => {
  const [closeMessage, setIsClose] = useState(false);

  const isClose = () => {
    setIsClose(!closeMessage);
  }

  if(!closeMessage ){
    return(
      <div className="contactPopup_container d-none d-lg-block">
        <div className="contactPopup_container_iconClose">
          <i className="fas fa-times contactPopup_container_iconClose__icon" onClick={isClose} />
        </div>
        <div className="contactPopup_container_contactContainer">
          <h6 className="h6">Besoin d'un renseignement?</h6>
          <p className="textWhite mt-2 mb-2">
            <i className="textWhite fas fa-mobile-alt mr-2" />
            07.77.49.37.57
          </p>
          <p className="textWhite">Du lundi au vendredi de 9h00 à 17h30</p>
        </div>
      </div>
    )
  }
  else if(props.location !== "/contact"&& closeMessage){
    return (
      <i className="open-message fas fa-ellipsis-h d-none d-lg-block"
        onClick={isClose}
      />
    )
  }
  else{
    return null;
  }
}
export default Message;