import React, { useEffect, useState } from 'react';
import { Container,Row,Col,Media } from 'reactstrap';
import NewsModal from '../components/NewsModal';
import Fade from "react-reveal/Fade";
import NewsModalStage from '../components/NewsModalStage';

const News = (props) => {
  const [connected, setIsConnected] = useState(true);
  const windowFB = async () => {
    try {
      await window.FB.XFBML.parse();
      setIsConnected(false);
    } catch (error) {

    }
  }

  useEffect(() => {
    windowFB();
    return(
      setIsConnected(false)
    )
  }, [connected]);

  return (
    <>
      <Container fluid className="p-news p-0">
        <Row
          style={{
            backgroundImage: "url(/img/headerService.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            width: "100%",
          }}
          className="m-0"
        >
          <Row className="p-news_header m-0">
            <Col lg="12" className="p-news_header_container">
              <h1 className="p-news_header_container_title">
                Toute l'actualitée d'APPePLAY Studio
              </h1>
              <p className="p-news_header_container_text">
                Suivez notre actualitée et restez informé des nouveautées que notre team vous prépare !
              </p>
            </Col>
          </Row>
        </Row>
        <Container className="p-0">
          <Row className= "m-0 pb-5 pt-5">
          {!connected ? (
            <>
              <Col xl="8" className="p-news_leftContainer">
                <Fade bottom>
                  <Media className="p-news_leftContainer_mediaContainer">
                    <Media className="pt-5">
                      <Media className="p-news_leftContainer_mediaContainer_img" object src="/img/stage.png" alt="stage appeplay studio" />
                    </Media>
                    <Media body className="p-1 pt-2">
                      <p className="text-mute">26/05/2021</p>
                      <Media heading className="text-nowrap">Premier Stagiaire !</Media>
                      Merci à Alexis actuellement en BTS SIO de nous rejoindre dans l'aventure APPePLAY Studio le temps d'un stage.
                      Une expérience nouvelle autant pour lui que pour nous car Alexis est notre tout premier stagiaire!...
                      <NewsModalStage />
                    </Media>
                  </Media>
                  <hr />
                  <Media className="p-news_leftContainer_mediaContainer">
                    <Media className="pt-5">
                      <Media className="p-news_leftContainer_mediaContainer_img" object src="/img/youtube.png" alt="chaîne youtube appeplay studio" />
                    </Media>
                    <Media body className="p-1 pt-2">
                      <p className="text-mute">13/04/2021</p>
                      <Media heading className="text-nowrap">Du nouveau sur notre chaine Youtube !</Media>
                      She's alive ! Enfin du contenu sur notre chaine Youtube ! Notre but étant aussi d'être au plus proche de notre communauté pour que vous puissiez suivre l'évolution de nos projets...
                      <NewsModal/>
                    </Media>
                  </Media>
                </Fade>
              </Col>
              <Col xl="4" className="p-news_rightContainer d-none d-xl-block">
                <div
                  className="fb-page"
                  data-href="https://www.facebook.com/Appeplay-Studio-105343098238269"
                  data-tabs="timeline"
                  data-width="400"
                  data-height="775"
                  data-small-header="false"
                  data-adapt-container-width="false"
                  data-hide-cover="false"
                  data-show-facepile="true"
                  crossOrigin="anonymous"
                >
                  <blockquote
                    cite="https://www.facebook.com/Appeplay-Studio-105343098238269"
                    className="fb-xfbml-parse-ignore"
                    crossOrigin="anonymous"
                  >
                  </blockquote>
                </div>
              </Col>
            </>
          ) : (
            <Col md={{size:8, offset:2}} className="p-news_leftContainer">
              <Fade bottom>
                <Media className="p-news_leftContainer_mediaContainer">
                  <Media className="pt-5">
                    <Media className="p-news_leftContainer_mediaContainer_img" object src="/img/stage.png" alt="stage appeplay studio" />
                  </Media>
                  <Media body className="p-1 pt-2">
                    <p className="text-mute">26/05/2021</p>
                    <Media heading className="text-nowrap">Premier Stagiaire !</Media>
                    Merci à Alexis actuellement en BTS SIO de nous rejoindre dans l'aventure APPePLAY Studio le temps d'un stage.
                    <NewsModal/>
                  </Media>
                </Media>
                <hr />
                <Media className="p-news_leftContainer_mediaContainer">
                  <Media className="pt-5" left href="#">
                    <Media object src="/img/youtube.png" width="300" alt="Generic placeholder image" />
                  </Media>
                  <Media body className="p-2">
                    <p className= "text-mute">13/04/2021</p>
                    <Media heading>Du nouveau sur notre chaine Youtube !</Media>
                      Du nouveau sur notre chaine Youtube !
                      She's alive ! Enfin du contenu sur notre chaine Youtube ! Notre but étant aussi d'être au plus proche de notre communauté pour que vous puissiez suivre l'évolution de nos projets...
                    <NewsModal />
                  </Media>
                </Media>
              </Fade>
            </Col>
          )}
          </Row>
        </Container>
      </Container>
      <div class="custom-shape-divider-bottom-1633610641">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
      </div>
    </>
  );
}

export default News;