import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Row,
} from "reactstrap";

const TeamCard = (props) => {
  return (
    <Card className="m-teamCard p-3 border-0">
      <img
        top
        className="m-teamCard_img mb-4 align-self-start"
        src={props.src}
        alt="web developer website mobile application"
        loading="eager"
        width="50%"
      />
      <CardBody className="m-teamCard_cardBody">
        <CardTitle className="m-teamCard_title" tag="h3">
          {props.title}
        </CardTitle>
        <CardSubtitle className="m-teamCard_subtitle" tag="h6">
          {props.subtitle}
        </CardSubtitle>
        <Row>
          <a href={props.linkedin} title={props.linkedInTitle}>
            {props.iconLinkedIn}
          </a>
          <a href={props.facebook} title={props.facebookTitle}>
            {props.iconFacebook}
          </a>
          <a href={props.twitter} title={props.twitterTitle}>
            {props.iconTwitter}
          </a>
          <a href={props.instagram} title={props.instagramTitle}>
            {props.iconInstagram}
          </a>
        </Row>
        <CardText>{props.text}</CardText>
        <Link className="a-buttons-btn_servicesLink" to={props.to} onClick={() => window.scrollTo(0, 0)}>
          {props.textLink}
        </Link>
      </CardBody>
    </Card>
  );
};

export default TeamCard;
