import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const NewsModal = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div className= 'o-modal'>
      <p className= "o-modal_link" onClick={toggle}>Lire la suite</p>
      <Modal className='modal-lg show' isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h2>Du nouveau sur notre chaine Youtube !</h2>
        </ModalHeader>
        <ModalBody className="p-4">
          <img className="o-modal_img mb-4" src="/img/youtube.png" alt="chaîne youtube appeplaystudio" />
          <p>
            he's alive ! Enfin du contenu sur notre chaîne <a href="https://www.youtube.com/channel/UCOIE-3wDAC0OFyPU5Z2_AYQ">YouTube</a> !<br /><br />
            Notre but étant aussi d'être au plus proche de notre communauté pour que vous puissiez suivre l'évolution de nos projets. Vous pourrez y écouter toutes les productions musicales liées à nos applications / jeux mobiles, mais également y découvrir par la suite des interviews de la team APPePLAY Studio ainsi que de petites sessions de tutos et astuces en tout genre !<br /><br />
            Nous ouvrons le bal en musique avec quelques cours extraits de notre projet "Freestyle Drum Machine" disponible prochainement sur toutes les plateformes Android (et on l'espère également sur IOS). Vous retrouverez bien évidemment une vidéo qui présentera l'application et son fonctionnement.<br />
            Et oui, il faut être encore un peu patient avant de savoir ce qui se cache derrière cette application, mais promis, on fait au plus vite !<br /><br />
            Pour ne rien rater, n'oubliez pas de vous abonner et d'actionner la cloche pour être prévenu lorsqu'une nouvelle vidéo sera en ligne.<br /><br />
            À très vite !<br />
          </p>
          <a href="https://www.youtube.com/channel/UCOIE-3wDAC0OFyPU5Z2_AYQ">Voir la chaine Youtube</a>
        </ModalBody>
        <ModalFooter>
          <p>Publié le: 13/04/2021</p>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default NewsModal;