import React from 'react';
import { Container } from 'reactstrap';
import ComingSoon from '../components/ComingSoon';

const Products = (props) => {
  return (
    <Container fluid className="p-products p-0">
      <ComingSoon />
    </Container>
  );
}

export default Products;