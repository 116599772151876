import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import Recaptcha from "react-recaptcha";
import { toast } from "react-toastify";
import { init, send } from "emailjs-com";
init("user_WIktaPG9HEBofBgbIKNpP");
export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phoneNumber: "",
      object: "",
      message: "",
      isVerified: false,
      error: null,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    const templateId = "template_puhoe3d";
    const serviceID = "service_ro3vtim";
    event.preventDefault();
    this.setState({ error: "" });
    try {
      if (this.state.isVerified) {
        await send(serviceID, templateId, {
          email: this.state.email,
          object: this.state.object,
          message: this.state.message,
          phoneNumber: this.state.phoneNumber,
          reply_to: this.state.email,
        }).then((value) => {
          this.setState({
            email: "",
            object: "",
            phoneNumber: "",
            message: "",
          });
          toast.success("Votre message a bien été envoyé!");
        });
      } else {
        toast.error("Merci de remplir les champs obligatoires");
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  recaptchaLoaded = () => {};

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  };

  render() {
    return (
      <>
        <Container fluid className="p-contact p-0">
          <Row
            style={{
              backgroundImage: "url(/img/header.jpg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              width: "100%",
            }}
            className="m-0"
          >
            <Row className="p-contact_header m-0">
              <Col lg="12" className="p-contact_header_container">
                <h1 className="p-contact_header_container_title">
                  Contacter APPePLAY Studio
                </h1>
                <p className="p-contact_header_container_text">
                  Vous vous posez des questions ?
                  <br />
                  Vous avez un projet bien précis en tête et vous vous demandez
                  s’il est réalisable ?
                  <br />
                  Contactez-nous ! Une demande de devis est possible.
                </p>
              </Col>
            </Row>
          </Row>
          <Row className="p-contact_row m-0">
            <Col
              lg={{ size: 6, offset: 3 }}
              sm="12"
              className="p-contact_row_form"
            >
              <h1 className="p-contact_row_form_title">Nous Contacter</h1>
              <p className="pb-3 p-contact_row_form_text">
                Vous avez une idée de projet, besoin de renseignements ? Notre
                équipe vous répondra le plus rapidement possible.
              </p>
              <Form onSubmit={this.handleSubmit} autoComplete="off">
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Entrez votre email *"
                    onChange={this.handleChange}
                    value={this.state.email}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Entrez votre numero de téléphone (facultatif)"
                    onChange={this.handleChange}
                    value={this.state.phoneNumber}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="object"
                    id="object"
                    placeholder="quel est l'objet de votre email *"
                    onChange={this.handleChange}
                    value={this.state.object}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    className="p-contact_row_form_textarea"
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Votre message"
                    onChange={this.handleChange}
                    value={this.state.message}
                  />
                </FormGroup>
                {this.state.error ? (
                  <p className="text-danger">{this.state.error}</p>
                ) : null}
                <FormGroup>
                  <Recaptcha
                    sitekey="6LcXyIsaAAAAAEMiGEIrvc0_PvO6na4tNl0yriG8"
                    render="explicit"
                    size="normal"
                    onloadCallback={this.recaptchaLoaded}
                    verifyCallback={this.verifyCallback}
                  />
                </FormGroup>
                {/* <Button className="a-buttons-btn-large btn-block" type="submit" onClick={this.handleSubmit}> */}
                <Button className="a-buttons-btn btn-block" type="submit">
                  Envoyer
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        <div class="custom-shape-divider-bottom-1633610641">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
          </svg>
        </div>
      </>
    );
  }
}
