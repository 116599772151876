import React, { useState } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap";

const CustomNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const changeBackgroundNavbar = () => {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const onClick = () => {
    window.scrollTo(0, 0)
    setIsOpen(false)
  }

  window.addEventListener("scroll", changeBackgroundNavbar);

  return (
    <Navbar
      dark
      className={navbar ? "m-navbar is--active m-0 p-0" : "m-navbar m-0 p-0"}
      expand="xl"
      variant="light"
    >
      <span className="m-navbar_wrap m-0 p-0">
        <NavbarBrand className="m-navbar_navbarBrand pl-2 pr-3" href="/">
          <img
            className="m-navbar_navbarBrand_logo"
            src="img/logo.png"
            alt="logo enterprise APPePLAY Studio website mobile application"
            width="120vw"
          />
        </NavbarBrand>
      </span>
      <NavbarToggler className="mr-4" onClick={toggle} />
      <Collapse className="m-navbar_collapse" isOpen={isOpen} navbar>
        <Nav className="mr-auto ml-2" navbar>
          <NavItem>
            <NavLink
              className="m-navbar_navLink"
              exact
              to="/"
              onClick={onClick}
              activeClassName="active"
              tag={RRNavLink}
            >
              Accueil
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="m-navbar_navLink"
              to="/service"
              onClick={onClick}
              activeClassName="active"
              tag={RRNavLink}
            >
              Nos Prestations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="m-navbar_navLink"
              to="/products"
              onClick={onClick}
              activeClassName="active"
              tag={RRNavLink}
            >
              Nos Produits
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="m-navbar_navLink"
              to="/news"
              onClick={onClick}
              activeClassName="active"
              tag={RRNavLink}
            >
              Actualité
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="m-navbar_navLink"
              to="/blog"
              onClick={onClick}
              activeClassName="active"
              tag={RRNavLink}
            >
              Blog
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="m-navbar_navLink"
              to="/contact"
              onClick={onClick}
              activeClassName="active"
              tag={RRNavLink}
            >
              Contact
            </NavLink>
          </NavItem>
        </Nav>
        <NavbarText>
          <a href="https://fr.linkedin.com/company/appeplay-studio?trk=public_profile_topcard-current-company">
            <i className="m-navbar_fab fab fa-linkedin ml-4" />
          </a>
          <a href="https://www.facebook.com/Appeplay-Studio-105343098238269">
            <i className="m-navbar_fab fab fa-facebook" />
          </a>
          <a href="https://twitter.com/appeplay">
            <i className="m-navbar_fab fab fa-twitter-square" />
          </a>
          <a href="https://www.instagram.com/appeplaystudio/">
            <i className="m-navbar_fab fab fa-instagram" />
          </a>
          <a href="https://youtube.com/channel/UCOIE-3wDAC0OFyPU5Z2_AYQ">
            <i className="m-navbar_fab fab fa-youtube" />
          </a>
          <a href="https://discord.gg/vgmDw4BvEh">
            <i className="m-navbar_fab fab fa-discord" />
          </a>
        </NavbarText>
      </Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
